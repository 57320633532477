import React from "react"
import styled from '@emotion/styled'
import borderHorizontal from '../images/borderHorizontal.svg';

const Wrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '20px',
  clear: 'both',
});

const Border = styled('div')({
  width: '100%',
  height: '2px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  top: 0,
  left: 0,
});

const HorizontalRule = ({ ...props }) => (
  <Wrapper>
    <Border />
  </Wrapper>
)

export default HorizontalRule
