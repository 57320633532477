import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import HorizontalRule from "../../components/horizontal-rule"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "podkrovni-atelier/atelier-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "podkrovni-atelier/atelier-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "podkrovni-atelier/atelier-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "podkrovni-atelier/atelier-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "podkrovni-atelier/atelier-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "podkrovni-atelier/atelier-6.jpg" }) { ...fluidImage }
    photo7: file(relativePath: { eq: "podkrovni-atelier/atelier-7.jpg" }) { ...fluidImage }
  }
`
const PodkrovniAtelierPage = ({ data }) => (
  <Layout title="Podkrovní ateliér">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Podkrovní ateliér, Praha 7, 2015</Heading>
      <Text>
        Podkroví prostor byl původně navržen jako nástavba 5-ti patrového domu a sloužil jako kanceláře. Ocelový krov má vazby vzdálené 2,5&nbsp;m, které vytváří pravidelnou modulovou osnovu. Tato osnova se promítla do dělení jednotlivých kanceláří na šířku 2,5&nbsp;m. Ocelová konstrukce krovu vynáší pomocí táhel i strop pod ní. Po ostranění příček vznikl rastr ocelových táhel a bylo nutno s nimi pracovat a zakomponovat je do nové dispozice. Rekonstrukcí ocelového krovu vznikly dva prostory: podkrovní byt a ateliér. Ateliér byl navržen jako otevřená dispozice, do které je vložen objem kuchyně a sociálního zařízení. Tyto objemy jsou vloženy do stavající modulové sítě tak aby zakryly ocelová táhla. Obklad navržených objemů je z akustického sadrokartonu. Za kuchyní je umístěno schodiště vedoucí na původní ocelovou lávku, ze které je dále přístup do vestavných skříní, servrovny a prostoru nad sociálkami. Osvětlení prostor je tvořeno led lištami umístěnými do hlinikových profilů. Délky profilů vycházejí z modulové sítě a jsou umístěny do ocelového krovu. Prostor byl navržen pro budoucí pronájem komerčního prostoru.
      </Text>
    </VerticalText>
    <HorizontalRule />
    <VerticalImage borderRight image={data.photo3.childImageSharp.fluid} />
    <VerticalImage image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage borderBottom borderTop image={data.photo5.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo6.childImageSharp.fluid} />
    <VerticalImage image={data.photo7.childImageSharp.fluid} />
  </Layout>
)

export default PodkrovniAtelierPage
